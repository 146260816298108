import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { get, post } from '../slices/api';
import { AxiosError } from 'axios';

type BonusState = {
  isLoading: boolean;
  error: any;
  data: any;
  name: any;
  pin: any;
  bonusValue: any;
  isError: boolean;
  description: string;
  title: string;
};

const initialState: BonusState = {
  isLoading: false,
  error: null,
  data: null,
  name: null,
  pin: null,
  bonusValue: null,
  isError: false,
  description: '',
  title: '',
};

const slice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setModalInfos(state, action) {
      state.description = action.payload.description;
      state.title = action.payload.title;
      state.isError = action.payload.isError;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setPhoneFormData(state, action) {
      action.payload.check = ['acceptedTerms', 'acceptedEmail', 'acceptedSMS', 'acceptedWhatsapp'];
      console.log('setPhoneFormData: ', action.payload);
      // state.codeFormData = action.payload
    },
  },
});

export default slice.reducer;

export const { setPhoneFormData } = slice.actions;

export function createBonus(membercard: string, bonusValue: string, phone: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if(Number(bonusValue) < 10) {
        throw new Error("minimumValue")
      } else if(Number(bonusValue) > 500) {
        throw new Error("maximumValue")
      }
      // await post('/promoter/create-bonus', { "membercard": membercard, "bonusValue": bonusValue, "phone": phone });
      dispatch(slice.actions.stopLoading());
      dispatch(
        slice.actions.setModalInfos({
          title: 'Bônus gerado com sucesso!',
          description:
            'Informe para o cliente confirmar o recebimento do SMS com valor de bônus gerado e a data de validade para o uso.',
          isError: false,
        })
      );
      return true;
    } catch (error) {
      dispatch(slice.actions.stopLoading());

      if(error?.message === "minimumValue") {
        dispatch(slice.actions.setModalInfos(
          {
            title: "Valor inserido abaixo do limite permitido.",
            description: "Você pode inserir bônus a partir de R$ 10. Tente novamente seguindo as regras da campanha. ",
            isError: true
          }
        ));
      } else if(error?.message === "maximumValue") {
        dispatch(slice.actions.setModalInfos(
          {
            title: "Valor inserido acima do limite permitido.",
            description: "Você pode inserir bônus de até R$ 500,00. Tente novamente seguindo as regras da campanha.",
            isError: true
          }
        ));
      } else {
        if (error instanceof AxiosError) {
          if (error.response?.status == 400) {
            dispatch(
              slice.actions.setModalInfos({
                title: error.response?.data?.title,
                description: error.response?.data?.description,
                isError: true,
              })
            );
          } else {
            dispatch(
              slice.actions.setModalInfos({
                title: 'Error no servidor',
                description: 'Tente novamente mais tarde.',
                isError: true,
              })
            );
          }
        } else {
          dispatch(
            slice.actions.setModalInfos({
              title: 'Error no servidor',
              description: 'Tente novamente mais tarde.',
              isError: true,
            })
          );
        }
      }

      return error?.message ?? 'error'; // A principio isso não serve pra nada
    }
  };
}

export function resendPin(phone: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await post('/auth/resendPin', { phone });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function verifyPin(
  pinCode: string,
  phone: string,
  check: string[],
  termsVersion: string,
  token: string | null
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var response = await axios.post('/auth/validatePin', {
        phone,
        pinCode,
        check,
        termsVersion,
        token,
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
