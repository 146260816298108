import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// import axios from '../../utils/axios';
import { get, put } from './api';

type BenefitState = {
  isLoading: boolean;
  error: any;
  benefitsList: { id: string; name: string; image: string; benefitType: string }[];
  reservation: any;
  benefit: any;
};

const initialState: BenefitState = {
  isLoading: false,
  error: null,
  benefitsList: [],
  benefit: null,
  reservation: null,
};

const slice = createSlice({
  name: 'benefits',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getBenefitsSuccess(state, action) {
      state.isLoading = false;
      state.benefitsList = action.payload;
    },

    getBenefitSuccess(state, action) {
      state.benefit = action.payload;
      state.isLoading = false;
    },

    getReservationSuccess(state, action) {
      state.reservation = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export function getBenefits() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const benefits = await get('/benefits/all-benefits');
      dispatch(slice.actions.getBenefitsSuccess(benefits.data));
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
    }
  };
}

export function getReservation(membercard: string, benefitId: string, benefitType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await get(`/benefits/get-user-redemption?memberCard=${membercard}&benefitId=${benefitId}&benefitType=${benefitType}`);

      const reservation = res.data;

      if (reservation?.user?.phone) {
        if (reservation.user.phone.length === 11) {
          reservation.user.phone = reservation.user.phone.replace(
            /(\d{2})(\d{5})(\d{4})/,
            '($1) $2-$3'
          );
        } else {
          reservation.user.phone = reservation.user.phone.replace(
            /(\d{2})(\d{4})(\d{4})/,
            '($1) $2-$3'
          );
        }
      }
      dispatch(slice.actions.getReservationSuccess(reservation));
      return reservation;
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
      return false;
    }
  };
}

export function confirmReservation(memberCard: string, benefitId: string, benefitType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await put(`/benefits/confirm-use`, {
        memberCard,
        benefitId,
        benefitType
      });
      return true;
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
      return e;
    }
  };
}

export function createReservation(memberCard: string, benefitId: string, benefitType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await put(`/benefits/redeem-and-confirm-use`, {
        memberCard,
        benefitId,
        benefitType
      });
      return true;
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
      return e;
    }
  };
}

export function getBenefitDetails(benefitId: string, benefitType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const benefit = await get(`/benefits/promoter-details?benefitId=${benefitId}&benefitType=${benefitType}`);
      dispatch(slice.actions.getBenefitSuccess(benefit.data[0]));
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.hasError(e));
    }
  };
}
